import React, { Component } from 'react';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "images/logo.png";
import { withRouter } from "react-router";
import { Alert } from 'antd';

import { Radio } from 'antd';
// import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/radio/style/index.css'

/*
    Do not use import 'antd/dist/antd.css'
    https://github.com/ant-design/ant-design/issues/9363#issuecomment-490545432

*/
import './checkbox-radio.css';


const Container = tw(ContainerBase)`min-h-screen bg-secondary-100 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`sm:mt-6 mt-2 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1`;
const CheckboxContainer = tw.div`flex flex-col text-xl items-center`;


const Question = tw.div`text-lg text-gray-600 mb-8 lg:mb-4 lg:mb-4 xl:mb-4 text-center`;

const Form = tw.form`mx-auto max-w-xs`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-400 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-400 disabled:bg-primary-100 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
// const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
// const Textarea = styled(Input).attrs({as: "textarea"})`
//   ${tw`h-24`}
// `
const Label = tw.label` top-0 left-0 tracking-wide font-semibold text-sm text-center	block`;
const InputContainer = tw.div`relative py-5 mt-6`;
const TextArea = tw.textarea`mt-5 w-full h-1/3 border-solid border border-gray-600 rounded p-1`;
const yesHeader = 'Anything else you want to tell us?';
const yesPlaceholder = 'You guys should change X to Y'
const noHeader = 'What happened? Help us adjust your tasks, so you can better achieve your goals.'
const noPlaceholder = 'Task was not interesting enough, Too hard for me ...'

class Checkin extends Component {
  constructor(){
    super();
    this.state = {
      selected:'',
      feedbackHeader:'',
      feedbackPlaceholder:'',
      completed: '',
      feedback: '',
      errors:[]
      
    }
   
  }

  shouldDisableSend = () => {
    return this.state.completed === '';
  }
  
  onFeedbackChange = (e) => {
    this.setState({feedback:e.target.value});
  }
  
  onRadioChange = (e) => {
    let newHeader = yesHeader;
    let newPlaceholder = yesPlaceholder;
    if(e.target.value === false){
      newHeader = noHeader;
      newPlaceholder = noPlaceholder
    }
    this.setState({completed:e.target.value, feedbackHeader: newHeader, feedbackPlaceholder: newPlaceholder});
    
  }
  
  onContinue = (e) => {
    this.props.history.push({pathname:this.props.nextUrl});
  }
  
  submit = () => {
    // const parsed = queryString.parse(this.props.location.search, {arrayFormat: 'comma'});
    const publicId = window.location.pathname.split('/').pop()
    var myHeaders = new Headers();
    
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("publicId", publicId);
    urlencoded.append("completed", this.state.completed);
    urlencoded.append("feedback",this.state.feedback);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
    };

    fetch("/api/checkin", requestOptions)
      .then(response => {
        if (response.ok || response.status === 400) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .then(result => {
        if(result.status === 1){
          this.props.history.push(`/report/${publicId}`)
        } else if (result.status === 0){
          this.setState({errors:result.data})
        } else {
          this.setState({errors:[{msg:'Unknown error. Please try again later'}]})
        } 
      })
      .catch(error => {
        this.setState({errors:[{msg:error.message}]})
      });
        
}



  render() {
    return (
      
      <AnimationRevealPage disabled={!this.props.animate} >
        <Container>
          <Content>
            <MainContainer>
              <LogoLink href='/'>
                <LogoImage src={logo} />
              </LogoLink>
              <MainContent>
                <Heading>{this.props.headingText}</Heading>
                <FormContainer>
                
                  <Question>{this.props.text} </Question>
                  
                  <Form>
                    <CheckboxContainer>                     
                      <Radio.Group 
                        style={tw`flex flex-col`} onChange={this.onRadioChange}>
                        {this.props.options.map ((item, index) => {
                          return <Radio   style={tw` text-xl`} key={item.value} value={item.value}>
                            {item.label}
                          </Radio>
                        })}
                      </Radio.Group>
                      
                    </CheckboxContainer>  

                    {
                      this.state.completed !== '' ? 
                      <>
                        <InputContainer tw="flex-1">
                          <Label htmlFor="feedback-input">{this.state.feedbackHeader}</Label>
                          <TextArea onChange={this.onFeedbackChange} id="feedback-input" name="message" placeholder={this.state.feedbackPlaceholder}/>
                        </InputContainer>
                      </>
                      :null 
      
                    
                    
                   }
                   
                   {/* Completed: {`${this.state.completed}`}
                   feedback: {this.state.feedback} */}
            
                    <SubmitButton onClick={this.submit}
                      disabled={this.shouldDisableSend()}
                      style={{
                        cursor: this.shouldDisableSend() ?'not-allowed' : 'auto'
                      }} 
                      type='button'>
                      <span className="text" >Send!</span>
                    </SubmitButton>  
                    {
                   
                   this.state.errors && this.state.errors[0] && this.state.errors.map((error,index) => {
                    return <Alert key={index} style={tw`mt-5`} message={error.msg} type="error" showIcon />
                   })
                  }      
                  </Form>
                </FormContainer>
              </MainContent>
            </MainContainer>
          </Content>
        </Container>
      </AnimationRevealPage>
    );
  }
}

export default withRouter(Checkin);
