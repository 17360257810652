import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "images/logo.png";
import { ReactComponent as MessageIcon } from "feather-icons/dist/icons/message-square.svg";
import  queryString from "query-string"
import { withRouter } from "react-router";
import { Alert } from 'antd';

import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import 'antd/lib/alert/style/index.css'

const Container = tw(ContainerBase)`min-h-screen bg-primary-300 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
// const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-4 tiny:mt-8`;


const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium border border-gray-400 placeholder-gray-500 text-sm  focus:outline-none focus:border-gray-600 bg-white mt-5 first:mt-0 text-black`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-300 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const TextContent = tw.div`lg:py-8 text-center`;
const Subheading = tw(SubheadingBase)`text-center text-primary-300`;
const Heading = tw(SectionHeading)`mt-4 font-black text-center text-2xl tiny:text-3xl sm:text-4xl lg:text-5xl text-center md:leading-tight`;
const Description = tw.p`mt-4 text-center md:text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-600`
const PhoneInputStyled = tw(PhoneInput)`
${tw`mt-5`}
  
  `;

  class Signup extends React.Component {

    constructor(){
      super()
      this.subheading = "Create your account";
      this.heading = <>Now, let the <span tw="text-primary-300">magic</span><wbr/> start</>;
      this.description = "Enter your name and phone number";
      this.logoLinkUrl = "/";
      this.submitButtonText = "Text my first task!";
      
      this.tosUrl = "#";
      this.privacyPolicyUrl = "#";
      this.signInUrl = "#";
      this.state = {
        phone:'',
        name:'',
        errors:[],
        tasks:[],
        days:'0'
      }
    }
    onPhoneChange = (phone) => {
      this.setState({phone:phone})
    }

    onDaysChange = (e) => {
      this.setState({days:e.target.value})

    }
    onNameChange = (e) => {
      this.setState({name:e.target.value})
    }

    showWarning = (e) => {
      return false;
    }

    submit = () => {
      const parsed = queryString.parse(this.props.location.search, {arrayFormat: 'comma'});

      var myHeaders = new Headers();
      
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("firstName", this.state.name);
      urlencoded.append("phoneNumber", this.state.phone);
      urlencoded.append("gender", parsed.q1);
      urlencoded.append("profession", parsed.q2);
      urlencoded.append("status", parsed.q3);
      urlencoded.append("isParent",parsed.q4);
      urlencoded.append("goals", parsed.q5);
      let timezone = "US/Central";
      try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

      } catch {}
      urlencoded.append("timezone",timezone)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
      };

      fetch("/api/onboard/register", requestOptions)
        .then(response => {
          if (response.ok || response.status === 400) {
            return response.json();
          } else {
            throw Error(response.statusText);
          }
        })
        .then(result => {
          if(result.status === 1){
            this.props.history.push('/get-started/success')
          } else if (result.status === 0){
            this.setState({errors:result.data})
          } else {
            this.setState({errors:[{msg:'Unknown error. Please try again later'}]})
          } 
        })
        .catch(error => {
          this.setState({errors:[{msg:error.message}]})
        });
          
  }

//   simulate = () => {
//     const parsed = queryString.parse(this.props.location.search, {arrayFormat: 'comma'});

//     var myHeaders = new Headers();
    
//     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

//     var urlencoded = new URLSearchParams();
//     urlencoded.append("firstName", this.state.name);
//     urlencoded.append("phoneNumber", this.state.phone);
//     urlencoded.append("gender", parsed.q1);
//     urlencoded.append("profession", parsed.q2);
//     urlencoded.append("status", parsed.q3);
//     urlencoded.append("isParent",parsed.q4);
//     urlencoded.append("goals", parsed.q5);
//     urlencoded.append("days",this.state.days);

//     let timezone = "US/Central";
//     try {
//       timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

//     } catch {}
//     urlencoded.append("timezone",timezone)
//     var requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: urlencoded,
//     };

//     fetch("/api/onboard/simulate", requestOptions)
//       .then(response => {
//         if (!response.ok) {
//           throw Error(response.statusText);
//         }
//         return response.json();
//       })
//       .then(result => {
//         if(result.status === 1){
          
//           this.setState({tasks:result.data})
//         } else if (result.status === 0){
//           this.setState({errors:result.data})
//         } else {
//           this.setState({errors:[{msg:'Unknown error. Please try again later'}]})
//         } 
//       })
//       .catch(error => {
//         this.setState({errors:[{msg:error.message}]})
//       });
        
// }

  render() {
    return  <AnimationRevealPage>
     <Container>
       <Content>
         <MainContainer>
           <LogoLink href={this.logoLinkUrl}>
             <LogoImage src={logo} />
           </LogoLink>
           <MainContent>
             <TextContent>
               {this.subheading && <Subheading>{this.subheading}</Subheading>}
               <Heading>{this.heading}</Heading>
               <Description>{this.description}</Description>
             </TextContent>
             <FormContainer>
               <Form>
               <Input onInput={this.onNameChange} type="text" placeholder="First name" />
                 <PhoneInputStyled
                  onChange={this.onPhoneChange}

                   name="phoneNumber"
                   type="text"
                   country={"us"}
                   enableAreaCodes={false}
                   onlyCountries={["us","ca"]}
                   inputClass="my-input"
                   containerClass="my-container"
                   containerStyle={
                     tw`mt-5 border-gray-400 focus:border-gray-600 transition-none`
                   }
                   inputStyle={
                     tw` w-full rounded-lg font-medium border placeholder-gray-500 text-sm focus:outline-none focus:shadow-none shadow-none border-gray-400 focus:border-gray-600 bg-white  text-black `
                   }
                   inputProps={{
                     name: 'phone',
                     required: true,
                     
                   }}
                 />

                 <SubmitButton onClick={this.submit} type='button' >
                   <MessageIcon className="icon" />
                   <span className="text">{this.submitButtonText}</span>
                 </SubmitButton>
                 {
                   
                   this.state.errors && this.state.errors[0] && this.state.errors.map((error,index) => {
                    return <Alert key={index} style={tw`mt-5`} message={error.msg} type="error" showIcon />
                   })

                 }
                 <p tw="mt-6 text-xs text-gray-600 text-center">
                   I agree to abide by SuperStart's{" "}
                   <a href='/terms' tw="border-b border-gray-500 border-dotted">
                     Terms of Service
                   </a>{" "}
                   and its{" "}
                   <a href='/privacy' tw="border-b border-gray-500 border-dotted">
                     Privacy Policy
                   </a>
                 </p>
                 <Input onInput={this.onDaysChange} type="text" placeholder="Number of days to simulate" />

                 <SubmitButton onClick={this.simulate} type='button' >
                   <span className="text">Simulate</span>

                   
                 </SubmitButton>
                 
                 <table style={{width:"100%",border:"1px solid black", marginLeft:"-15rem"}}>
                 
                  <tr>
                    <th>Day</th>
                    <th>Task Description</th>
                    <th>Category</th>
                    <th>Duration</th>

                    <th>Included</th>
                    <th>Excluded</th>
                    <th>Weekend</th>
                    <th>Resource</th>


                  </tr>
                  {
                  this.state.tasks.map((item, index) => {
                  return <tr style={{border:"1px solid black"}} key={index}>
                    <td>Day {index + 1}</td>
                    <td style={{border:"1px solid black"}}>{item.text}</td>
                    <td style={{border:"1px solid black"}}>{item.category}</td>
                    <td style={{border:"1px solid black"}}>{item.duration}</td>
                    <td style={{border:"1px solid black"}}>{JSON.stringify(item.includedPersona)}</td>
                    <td style={{border:"1px solid black"}}>{JSON.stringify(item.excludedPersona)}</td>

                    <td style={{border:"1px solid black"}}>{item.weekdayWeekend}</td>
                    <td style={{border:"1px solid black"}}>{item.resource}</td>

                  </tr>
                  })
                 }
                </table>
                 
               </Form>
             </FormContainer>
           </MainContent>
         </MainContainer>
       </Content>
     </Container>
   </AnimationRevealPage>
   }
}
  export default withRouter(Signup)
