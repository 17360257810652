import React, {useState} from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg"
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg"
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg"
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";


import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-green-800 text-gray-100`;


const TestimonialsSlider = styled(Slider)`
  ${tw`flex mx-auto max-w-xs sm:max-w-xl lg:max-w-4xl text-left bg-primary-100 rounded-lg text-gray-900 z-10`}
  .slick-track {
    ${tw`flex!`}
  }
  .slick-slide {
    ${tw`h-auto`}
  }
  .slick-slide > div {
    ${tw`h-full`}
  }
`;
const Testimonial = tw.div`px-6 py-12 sm:px-20 sm:py-16 focus:outline-none flex! flex-col justify-between h-full`
const QuoteContainer = tw.div`relative`
const QuoteIcon = tw(QuoteIconBase)`absolute opacity-15 top-0 left-0 transform -translate-y-2 -translate-x-1/2 sm:-translate-x-full w-10 fill-current text-primary-500`
const Quote = tw.blockquote`font-medium sm:font-normal relative text-base	 sm:text-xl text-center sm:text-left`
const CustomerInfoAndControlsContainer = tw.div`mt-8 flex items-center flex-col sm:flex-row justify-center text-center sm:text-left`
const CustomerNameAndProfileContainer = tw.div`mt-4 sm:mt-0 sm:ml-4 flex flex-col`
const CustomerName = tw.span`text-base	 sm:text-xl font-semibold italic`
const ControlsContainer = tw.div`sm:ml-auto flex`
const ControlButton = styled.button`
  ${tw`text-gray-600 hover:text-primary-700 focus:outline-none transition-colors duration-300 ml-4 first:ml-0 sm:first:ml-4 mt-4 sm:mt-0`}
  .icon {
    ${tw`fill-current w-6`}
  }
`;
const FeatureContainer = tw.div`relative flex mx-auto max-w-xs sm:max-w-xl lg:max-w-4xl   py-6 z-10 mt-8 sm:px-20`
const Feature = tw.div`relative font-bold text-lg sm:text-2xl text-center  text-primary-500`

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 transform  translate-y-32 text-green-600 opacity-25`

export default ({
  subheading = "",
  heading = "Testimonials",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  testimonials = [
    {
      customerName: "The most sold self-help book “The 7 Habits of Highly Effective People”",
      featureText: "At SuperStart, we help plan your schedule based on your priorities, every day.",
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=256&h=256&q=80",
      quote:
        "The key is not to prioritize what’s on your schedule, but to schedule your priorities."
    },
    {
      customerName: "The #1 New York Times bestseller “Atomic Habit”",
      featureText: "SuperStart is here to help make your goal crystal clear from the get-go",
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=256&h=256&q=80",
      quote:
        "Many people think they lack motivation when what they really lack is clarity"
    },
    {
      customerName: "#1 bestseller from New York Times Book Review “Outliers”",
      featureText: "We believe in practice at SuperStart. We help our users practice each day to better themselves.",
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=256&h=256&q=80",
      quote:
        "Practice isn't the thing you do once you're good. It's the thing you do that makes you good"
    }
  ]
}) => {
  const [sliderRef, setSliderRef] = useState(null)

  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <TestimonialsSlider arrows={false} ref={setSliderRef}>
          {testimonials.map((testimonial, index) => (
            
            <Testimonial key={index}>
              <QuoteContainer>
                <QuoteIcon />
                <Quote>
                  {testimonial.quote}
                  <CustomerName>
                    { `  -  `} {testimonial.customerName}
                  </CustomerName>
                </Quote>
                
              </QuoteContainer>
              <CustomerInfoAndControlsContainer>
                <CustomerNameAndProfileContainer>
                  
                  {/* <CustomerProfile>
                    {testimonial.customerProfile}
                  </CustomerProfile> */}
                 
                </CustomerNameAndProfileContainer>
                <ControlsContainer>
                  <ControlButton onClick={sliderRef?.slickPrev}>
                    <ArrowLeftIcon className="icon" />
                  </ControlButton>
                  <ControlButton>
                    <ArrowRightIcon className="icon" onClick={sliderRef?.slickNext}/>
                  </ControlButton>
                </ControlsContainer>
                
              </CustomerInfoAndControlsContainer>
              <FeatureContainer>
                <Feature >{testimonial.featureText}</Feature>
          
            
              </FeatureContainer>
            </Testimonial>
            
            
          ))}
          
        </TestimonialsSlider>
        <DecoratorBlobContainer>
          <DecoratorBlob1/>
          {/* <DecoratorBlob2/> */}
        </DecoratorBlobContainer>
        
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
