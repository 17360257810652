import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "images/logo.png";
import  queryString from "query-string"
import { withRouter } from "react-router";

import 'react-phone-input-2/lib/bootstrap.css'
import 'antd/lib/alert/style/index.css'
import ControlledCheckbox from "components/components/Checkbox/ControlledCheckboxGroup";
import {  Radio } from 'antd';
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/radio/style/index.css'



const SubmitButton = styled.button`
  ${tw`mt-5  font-semibold bg-primary-300 text-gray-100  py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Form = tw.div`flex`
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;

const CheckboxContainer = tw.div`flex flex-col text-xl items-center`;
const options = [
    {label: 'Lady 👩', value:'WOMAN'},
    {label:'Gentleman 👨', value: 'MAN'},
    {label:'Prefer not to say / Other 🌈', value:'OTHER'}];
  class Admin extends React.Component {

    constructor(){
      super()
      this.subheading = "Create your account";
      this.heading = <>Now, let the <span tw="text-primary-300">magic</span><wbr/> start</>;
      this.description = "Enter your name and phone number";
      this.logoLinkUrl = "/";
      this.submitButtonText = "Text my first task!";
      
      this.tosUrl = "#";
      this.privacyPolicyUrl = "#";
      this.signInUrl = "#";
      this.state = {
        users:[],
        errors:[]
      }
    }
    onPhoneChange = (phone) => {
      this.setState({phone:phone})
    }

    onNameChange = (e) => {
      this.setState({name:e.target.value})
    }

    showWarning = (e) => {
      return false;
    }

    findUsers = () => {
      
      var requestOptions = {
        method: 'Get',
      };

      fetch("/admin/users/", requestOptions)
        .then(response => {
          if (response.ok || response.status === 400) {
            return response.json();
          } else {
            throw Error(response.statusText);
          }
        })
        .then(result => {
          if(result.status === 1){
            this.setState({users:result.data})
          } else if (result.status === 0){
            this.setState({errors:result.data})
          } else {
            this.setState({errors:[{msg:'Unknown error. Please try again later'}]})
          } 
        })
        .catch(error => {
          this.setState({errors:[{msg:error.message}]})
        });
          
  }

  render() {
    return  <div>
        <div>

        <SubmitButton onClick={this.findUsers}>Get Users </SubmitButton>

        </div>
        <div>
                 {this.state.users.map ((item, index) => {
                          return <div  key={item.phoneNumber} >
                            {item.firstName}
                            {JSON.stringify(item.profile)}
                            </div>
                })}
        </div>
        <div>
                <select>
                {this.state.users.map ((item, index) => {
                          return <option  key={item.phoneNumber} value={item.publicId}>
                            {item.firstName}</option>
                })}
                </select>
                
        </div>
        <Form>
                <InputContainer>
                    <Label htmlFor="name-input">Your Name</Label>
<select>Hello</select>                  
</InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input id="email-input" type="email" name="email" placeholder="E.g. john@mail.com" />
                  </InputContainer>

                  <CheckboxContainer>
                  <Label htmlFor="email-input">Gender</Label>

                    
                      <ControlledCheckbox options={options} maxCheckCount={4}></ControlledCheckbox>
{
                    //  <Radio.Group 
                    //   style={tw`flex flex-col`} >
                    //   {options.map ((item, index) => {
                    //     return <Radio   style={tw` text-xl`} key={item.value} value={item.value}>
                    //       {item.label}
                    //     </Radio>
                    //   })}

                  
                    // </Radio.Group>
                    }
                      

                  </CheckboxContainer>
        </Form>
     </div>
   }
}
  export default withRouter(Admin)
