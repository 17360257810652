import React, { Component } from 'react';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import logo from "images/logo.png";
import { withRouter } from "react-router";
import  queryString from "query-string"

import { Radio } from 'antd';
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/radio/style/index.css'

/*
    Do not use import 'antd/dist/antd.css'
    https://github.com/ant-design/ant-design/issues/9363#issuecomment-490545432

*/
import './checkbox-radio.css';
import { Link } from "react-router-dom";
import ControlledCheckbox from "components/components/Checkbox/ControlledCheckboxGroup";


const Container = tw(ContainerBase)`min-h-screen bg-secondary-100 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`sm:mt-6 mt-2 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1`;
const CheckboxContainer = tw.div`flex flex-col text-xl items-center`;


const Question = tw.div`text-lg text-gray-600 mb-8 lg:mb-4 lg:mb-4 xl:mb-4 text-center`;

const Form = tw.form`mx-auto max-w-xs`;
const SubmitButton = styled.button`
  ${tw`mt-10 tracking-wide font-semibold bg-primary-400 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-400 disabled:bg-primary-100 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Back = tw.p`mt-8 text-sm text-gray-600 text-center`;
// MyComponent.js

class Onboard extends Component {
  constructor(){
    super();
    this.state = {
      selected:[]
    }
   
  }
  onCheckboxChange = (values) => {
    this.setState({selected:values});
  }
  onRadioChange = (e) => {
    this.setState({selected:[e.target.value]});
  }

  onContinue = (e) => {
    this.setState({selected:[]});
    this.props.history.push({pathname:this.props.nextUrl, search:this.formQueryParams()});
  }
  onBack = (e) => {
    
    this.props.history.goBack();
  }
  
  formQueryParams = () => {
    const parsed = queryString.parse(this.props.location.search, {arrayFormat: 'comma'});
    let newQp ={ q1:parsed.q1, q2:parsed.q2, q3:parsed.q3, q4:parsed.q4, q5:parsed.q5}
    newQp[this.props.questionId] = this.state.selected
    // console.log('newQp', newQp);
    return queryString.stringify(newQp, {arrayFormat: 'comma'})  ;
    // return parsed.filter((qp)=> ['q1','q2','q3','q4','q5','q6'].includes(qp));
  }
  disableContinue = () => {
    return this.state.selected.length === 0 || (this.props.selectCount &&  this.state.selected.length < this.props.selectCount) 
  }

  render() {
    return (
      <AnimationRevealPage disabled={!this.props.animate} >
        <Container>
          <Content>
            <MainContainer>
              <LogoLink href='/'>
                <LogoImage src={logo} />
              </LogoLink>
              <MainContent>
                <Heading>{this.props.headingText}</Heading>
                <FormContainer>
                
                  <Question>{this.props.text} </Question>
                  
                  <Form>
                    <CheckboxContainer>
                      {
                        this.props.selectCount !== undefined
                        ?
                        <ControlledCheckbox options={this.props.options} maxCheckCount={this.props.selectCount} onChange={this.onCheckboxChange}></ControlledCheckbox>
  
                        : <Radio.Group 
                        style={tw`flex flex-col`} onChange={this.onRadioChange}>
                        {this.props.options.map ((item, index) => {
                          return <Radio   style={tw` text-xl`} key={item.value} value={item.value}>
                            {item.label}
                          </Radio>
                        })}
  
                    
                      </Radio.Group>
                      }
                    </CheckboxContainer>               
                    <SubmitButton onClick={this.onContinue} 
                      disabled={this.disableContinue()}
                      style={{
                        cursor: this.disableContinue() ?'not-allowed' : 'auto'
                      }} type='button'>
                      <span className="text" >Continue</span>
                    </SubmitButton>
                    
                    { this.props.backUrl && 
                    <Back>
                        <Link to={{
                          pathname: this.props.backUrl,
                          search: `${this.props.location.search}`,
                          
                        }}>Back</Link>
                    </Back>
                    }               
                  </Form>
                </FormContainer>
              </MainContent>
            </MainContainer>
          </Content>
        </Container>
      </AnimationRevealPage>
    );
  }
}

export default withRouter(Onboard);
