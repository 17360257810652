import React from "react";
import "./progress.css";
import styled, {keyframes,css} from "styled-components"
import tw from "twin.macro";

const loading = props => {
  return keyframes`
    0% { width: 0; }
    100% { width: ${props.progress}%}; 
  `
}

const animation = props =>
  css`
    ${loading} 3s normal forwards;
  `

const ProgressDiv = styled.div`
  animation: ${animation};
  box-shadow: 0 10px 40px -10px rgb(146, 51, 51);
  border-radius: 100px;
  background: #D86E42;
  height: 40px;
  width: 0;
`
class Progress extends React.Component {

    
    render() {
      return <div className="progress" style={this.props.style}>
        
       <ProgressDiv progress={this.props.progress}/>
       <div style={tw`mx-5 absolute font-bold`}>{this.props.text}</div>
    </div>;
    }
  }

  export default Progress
