import React, { Component } from 'react';

import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import Progress from "components/components/Progress/Progress"
import ControlledCheckbox from "components/components/Checkbox/ControlledCheckboxGroup";
import { withRouter } from "react-router";
import { Alert } from 'antd';

const CheckboxContainer = tw.div`flex flex-col text-xl items-center`;
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-lg mx-auto py-2 sm:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div`py-2 sm:py-10`;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`sm:m-20 m-5`;
const Subheading = tw.div`font-semibold text-primary-300 text-xl  text-left lg:text-left`;
const Heading = tw(SectionHeading)`text-center sm:text-left pt-2 pb-5 sm:py-10`;
const Description = tw.p` text-left mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-gray-700`;
const DescriptionBig = tw.p` text-left  leading-relaxed text-base sm:text-2xl mt-4 text-gray-700`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold text-gray-700`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-300 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);
const Category = tw.div`py-2 flex justify-between items-center sm:p-5`
const ProgressContainer = tw.div`flex flex-col justify-around`

const HeadingContainer = tw.div`flex flex-row justify-between`
const FocusHeading = tw.div` text-base sm:text-3xl	font-extrabold text-primary-300`
const options = [
  {label:'Physical Health',value:'PHYSICAL'},
  {label:'Mental Health',value:'MENTAL'},
  {label:'Family & Friends', value:'FAMILY'},
  {label:'Love Life', value:'LOVE'},
  {label:'Career',value: 'CAREER'},
  {label:'Personal Growth',value:'PERSONAL'},
  {label:'Finance',value:'FINANCE'},
  {label:'Fun & Entertainment', value:'FUN'}]

  const heading = "Progress Report";
  const description = "You have beaten the average completion rate of all users. Keep up the good work!";
  
  class Report extends Component{
    constructor(){
      super();
      this.state = {
        subheading:"",
        errors:[],
        categories:[]
      }
      this.mapping = new Map()
      this.mapping.set('PHYSICAL','Physical Health')
      this.mapping.set('MENTAL','Mental Health')
      this.mapping.set('FAMILY','Family & Friends')
      this.mapping.set('LOVE','Love Life')
      this.mapping.set('CAREER','Career')
      this.mapping.set('PERSONAL','Personal Growth')
      this.mapping.set('FINANCE','Finance')
      this.mapping.set('FUN','Fun & Entertainment')
    }
    getSubHeading = ()=> {
      let total = 0
      let completed = 0
      for (const item of this.state.categories) {
        total = total + item.notCompletedCount + item.completedCount;
        completed = completed + item.completedCount;
      }
      let subheading = `By ${new Date().toLocaleDateString("en-US")}, you have been assigned a total of ${total} tasks. You have completed ${completed} of them, reaching a completion rate of ${Math.trunc(100* completed/total)}%.`;
      return subheading;
    }
    componentDidMount() {
      this.getReport();
    }
    fetchReportData = (reportData) => {
      let filtered = reportData.filter((item) => {
        if(item.completedCount + item.notCompletedCount > 0)
          return true;
        return false;
      })
      filtered.sort((a,b) => {
        if(b.completedCount === a.completedCount){
          return b.completedCount - a.completedCount;
        }
        return b.completedCount - a.completedCount
      })
      this.setState({categories:filtered.slice(0,3)});
    }
    getReport = () => {
      const publicId = window.location.pathname.split('/').pop()
      var myHeaders = new Headers();
      
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("publicId", publicId);
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
      };
      fetch("/api/report", requestOptions)
      .then(response => {
        if (response.ok || response.status === 400) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .then(result => {
        if(result.status === 1){
          this.fetchReportData(result.data.result)
        } else if (result.status === 0){
          this.setState({errors:result.data})
        } else {
          this.setState({errors:[{msg:'Unknown error. Please try again later'}]})
        } 
      })
      .catch(error => {
        this.setState({errors:[{msg:error.message}]})
      });
    }
    render() {
return <Container>
      <Content>
        {/* <TwoColumn> */}
        {/* <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column> */}
        <Column>
          <FAQContent>
          <DescriptionBig>Success! 🎉  &nbsp; You have logged your progress!</DescriptionBig>

          {
                   
                   this.state.errors && this.state.errors[0] && this.state.errors.map((error,index) => {
                    return <Alert key={index} style={tw`mt-5`} message={error.msg} type="error" showIcon />
                   })

                 }
            <Heading>{heading}</Heading>
             <Subheading>{this.getSubHeading()}</Subheading>

            <DescriptionBig>Great job! <span style={tw`text-5xl`}>👍</span></DescriptionBig>



            <Column>
             
              {
                
                this.state.categories.map((element, index )=> {
                return  <Category key={index}>
                  <FocusHeading>{index + 1}. {this.mapping.get(element.category)}</FocusHeading> 
                  <Progress style={{width: "50%", minWidth:"50px"}} progress={ 100 * element.completedCount/(element.completedCount + element.notCompletedCount)} text={`${element.completedCount}/${element.completedCount + element.notCompletedCount}`}/>
                  </Category>

                })
                

              }
              {/* <Category><FocusHeading>2. Love</FocusHeading> <Progress progress="20" /></Category>
              <Category><FocusHeading>3. Career</FocusHeading> <Progress progress="30" /></Category> */}

            </Column>

            <DescriptionBig>{description}</DescriptionBig>



            {/* <FAQSContainer>
              <Subheading>Suggestions</Subheading>

              {faqs.map((faq, index) => (
                <FAQ
                  key={index}
                  onClick={() => {
                    toggleQuestion(index);
                  }}
                  className="group"
                >
                  <Question>
                    <QuestionText>{faq.question}</QuestionText>
                    <QuestionToggleIcon>
                      {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                    </QuestionToggleIcon>
                  </Question>
                  <Answer
                    variants={{
                      open: { opacity: 1, height: "auto", marginTop: "16px" },
                      collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    {faq.answer}
                  </Answer>
                </FAQ>
              ))}
            </FAQSContainer> */}
            {/* <Subheading>Want to switch priorities? Expand </Subheading>
            <FAQ>
                 
                  <Question>
                    <QuestionText>Want to switch priorities? Expand to change</QuestionText>
                    <QuestionToggleIcon>
                       <MinusIcon /> 
                    </QuestionToggleIcon>
                  </Question>
                  <Answer
                    variants={{
                      open: { opacity: 1, height: "auto", marginTop: "16px" },
                      collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                    }}
                    initial="collapsed"
                    animate="open"
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                      <CheckboxContainer>
                      
                        <ControlledCheckbox options={options} maxCheckCount={3} onChange={this.onCheckboxChange}></ControlledCheckbox>
  
                        
  
                    
                      
                    </CheckboxContainer> 
                    </Answer>
</FAQ> */}
          </FAQContent>
        </Column>
        {/* </TwoColumn> */}
      </Content>
    </Container>
    }
  }
  export default withRouter(Report);

// export default ({
//   subheading = "By 01/19/2021, you have been assigned a total of 68 tasks. You have completed 48 of them, reaching a completion rate of 70%.",
//   heading = "Weekly Report",
//   description = "You have beat the average rate of 65%. You are also on track to reach the next level of Mental health with 11 more tasks.",
//   imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
//   imageContain = false,
//   imageShadow = true,
//   faqs = null
// }) => {
//   /*
//    * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
//    * the faqs prop
//    */
//   const defaultFaqs = [
//     {
//       question: "Completion rate",
//       answer:
//         " Among the 3 priorities, you have the lowest completion rate in Finance. It suggests you find it hardest to work on your financial goal. And that's exactly why it's a priority worth focusing on! Try to pay more attention to the finance tasks next week. And reward yourself once you complete a finance daily to-do."
//     },
//     {
//       question: "Do you have 2 Bedroom suites ?",
//       answer:
//         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
//     },
//     {
//       question: "Are Wi-Fi costs included in the price ?",
//       answer:
//         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
//     },
//     {
//       question: "Where can I reach you for support ?",
//       answer:
//         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
//     }
//   ];

//   if (!faqs || faqs.length === 0) faqs = defaultFaqs;

//   const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

//   const toggleQuestion = questionIndex => {
//     if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
//     else setActiveQuestionIndex(questionIndex);
//   };

//   return (
    
//   );
// };
