import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";

import Header from "../headers/mini.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const FeatureList = tw.ul`mt-6 sm:mt-12 leading-tight	 tiny:leading-loose`;
const Feature = tw.li`flex items-center`;

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-5 md:py-24 `;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-6 tiny:mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-black text-3xl sm:text-4xl lg:text-5xl text-gray-900 leading-tight`;



// const Actions = styled.div`
//   ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
//   input {
//     ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
//   }
//   button {
//     ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-400 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
//   }
// `;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
const features = [
  "Choose your priorities",
  "Discover a new thing to do every day",
  "Reach your goals faster, and enjoy a happier life",
  ]
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-400 flex-shrink-0`;
const FeatureText = tw.p`ml-2 font-normal sm:font-semibold text-gray-700 text-left`;

const PrimaryButton = tw(PrimaryButtonBase)`bg-primary-300 mt-8 inline-block w-56 tracking-wide text-center py-5 text-base sm:text-xl`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Small steps       
            </Heading>
            <Heading>
             to a <span tw="text-primary-300">big life</span>
            </Heading>
             <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
            </FeatureList>
            <PrimaryButton as="a" href="/get-started" css={tw`rounded-full`}>
                Get Started!
            </PrimaryButton>
           
          </LeftColumn>
          <RightColumn>
          <ResponsiveVideoEmbed
                url="https://player.vimeo.com/video/500321978"
                background="transparent"
              />
            <IllustrationContainer>
            
              {/* <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" /> */}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
