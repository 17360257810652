import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "images/logo.png";
import { withRouter } from "react-router";

import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
// import './phoneEntry.css'
import 'react-phone-input-2/lib/bootstrap.css'
// import 'react-phone-input-2/lib/style.css'

const Container = tw(ContainerBase)`min-h-screen bg-green-700 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
// const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-300 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;


const Form = tw.form`mx-auto max-w-xs`;


const TextContent = tw.div`lg:py-8 text-center`;
const Subheading = tw(SubheadingBase)`text-center`;
const Heading = tw(SectionHeading)`mt-4 font-black text-center text-3xl sm:text-4xl lg:text-5xl text-center md:leading-tight`;
const Description = tw.p`mt-4 text-center md:text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-600`
const Description2 = tw.p`text-center md:text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-600`


  class Signup extends React.Component {

    constructor(){
      super()
      // this.subheading = "Success!";
      this.heading = <>Registration successful! </>;
      this.description = "You have sucessfully registered!" 
      this.description2 =  "Please check your phone for your first task!";
      
      this.tosUrl = "#";
      this.privacyPolicyUrl = "#";
      this.signInUrl = "#";
      this.state = {
        phone:'',
        name:'',
        response:''
      }
    }

  render() {
    return  <AnimationRevealPage>
     <Container>
       <Content>
         <MainContainer>
           <LogoLink href={this.logoLinkUrl}>
             <LogoImage src={logo} />
           </LogoLink>
           <MainContent>
             <TextContent>
               {this.subheading && <Subheading>{this.subheading}</Subheading>}
               <Heading>{this.heading}</Heading>
               <Description>{this.description}</Description>
               <Description2>{this.description2}</Description2>
             </TextContent>
             <FormContainer>
               <Form action='/'>   
                  <SubmitButton type='submit' >
                   <span className="text">Back to home page</span>
                 </SubmitButton>
               </Form>
             </FormContainer>
           </MainContent>
         </MainContainer>
       </Content>
     </Container>
   </AnimationRevealPage>
   }
}
  export default withRouter(Signup)
