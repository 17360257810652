import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "images/logo.png";
import { withRouter } from "react-router";

import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
// import './phoneEntry.css'
import 'react-phone-input-2/lib/bootstrap.css'
// import 'react-phone-input-2/lib/style.css'

const Container = tw(ContainerBase)`min-h-screen bg-secondary-100 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
// const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;

const TextContent = tw.div`lg:py-8 text-center`;
const Subheading = tw(SubheadingBase)`text-center`;
const Heading = tw(SectionHeading)`mt-4 font-black text-center text-3xl sm:text-4xl lg:text-5xl text-center md:leading-tight`;
const Description = tw.p`mt-4 text-center md:text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-600`
const Description2 = tw.p`mt-4 text-5xl text-secondary-100`


  class CheckInDone extends React.Component {

    constructor(){
      super()
      // this.subheading = "Success!";
      this.heading = <>Success!</>;
      this.description = "You have logged your progress!" 
      this.description2 ="🎉";
      this.tosUrl = "#";
      this.privacyPolicyUrl = "#";
      this.signInUrl = "#";
      this.state = {
        phone:'',
        name:'',
        response:''
      }
    }

  render() {
    return  <AnimationRevealPage>
     <Container>
       <Content>
         <MainContainer>
           <LogoLink href='/'>
             <LogoImage src={logo} />
           </LogoLink>
           <MainContent>
             <TextContent>
               {this.subheading && <Subheading>{this.subheading}</Subheading>}
               <Heading>{this.heading}</Heading>
               <Description>{this.description}</Description>
               <Description2>{this.description2}</Description2>
             </TextContent>
           </MainContent>
         </MainContainer>
       </Content>
     </Container>
   </AnimationRevealPage>
   }
}
  export default withRouter(CheckInDone)
