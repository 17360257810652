import React from "react";
import tw from "twin.macro"; //eslint-disable-line

import { css } from "styled-components/macro"; //eslint-disable-line
// import Hero1 from "components/hero/BackgroundAsImage.js";
// import Hero2 from "components/hero/BackgroundAsImageWithCenteredContent";
// import Hero3 from "components/hero/FullWidthWithImage";
// import Hero4 from "components/hero/TwoColumnWithFeaturesAndTestimonial";
// import Hero5 from "components/hero/TwoColumnWithInput";
// import Hero6 from "components/hero/TwoColumnWithPrimaryBackground";
// import Hero7 from "components/hero/TwoColumnWithVideo";
import HHHero from "components/hero/HappyHabitHero"
import Cta from "components/cta/GetStartedLight"

import HHFeature from "components/features/HHFeature";
import HHTestimonial from "components/testimonials/HHTestimonial"

import HHFooter from "components/footers/HappyHabitFooter";
// import HappHabitLandingPage from "App";
const StyledDiv = tw.div`font-display min-h-screen text-secondary-500  pt-2 px-8 tiny:p-8  overflow-hidden`;

export default () => (
    <StyledDiv className="App">
        {/* <Hero1></Hero1> */}
        {/* <Hero2></Hero2> */}
        {/* <Hero3></Hero3> */}
        {/* <Hero4></Hero4> */}
        {/* <Hero5></Hero5> */}
        <HHHero></HHHero>
        {/* <Feature/> */}
        <HHFeature/>
        <HHTestimonial/>
        
        <Cta></Cta>

        {/* <Hero6></Hero6> */}
        <HHFooter></HHFooter>
  </StyledDiv>
);
