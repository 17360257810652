import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import Feedback from "components/feedback/Feedback"


import HappHabitLandingPage from "demos/HappyHabbitLandingPage"
import Onboard from "pages/OnboardNew.js";



import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Checkin from "pages/CheckIn"
import HHSignup from "pages/HHSignup";
import Success from "pages/Success";
import HHTermsOfService from "pages/HHTermsOfService";
import HHPrivacyPolicy from "pages/HHPrivacyPolicy";
import CheckInDone from "pages/CheckInDone"
import Admin from "pages/Admin"
import Report from "pages/Report"
export default function App() {
  return (
  <>
      <Feedback email />
      <Router>
        <Switch>
          <Route exact path="/get-started">
              <Onboard
                questionId='q1'
                animate={true} 
                options={[
                  {label: 'Lady 👩', value:'WOMAN'},
                  {label:'Gentleman 👨', value: 'MAN'},
                  {label:'Other 🌈', value:'OTHER'},
                  {label:'Prefer not to say', value:'NOTSAY'}
                ]}
                nextUrl="/get-started/2" 
                headingText = "Gender" 
                text="Please tell us a little more about yourself, so that we can provide the best customize recommendation for you." />
          </Route>
          <Route exact path="/get-started/2">
              <Onboard
                questionId='q2'           
                animate={false} 
                options={[
                  {label:'Professional', value:'PROFFESSIONAL'},
                  {label:'Student', value:'STUDENT'},
                  {label: 'In between jobs', value:'BETWEEN'},
                  {label:'Retired', value:'RETIRED'}]}
                nextUrl="/get-started/3" 
                headingText = "Profession" 
                text="What do you do for a living?"
                backUrl="/get-started"
                />
          </Route>
          <Route exact path="/get-started/3">
              <Onboard
                questionId='q3'
                animate={false} 
                options={[
                  {label:'Single',value:'SINGLE'},
                  {label:'In a relationship',value:'RELATIONSHIP'},
                  {label:'Married', value:'MARRIED'}]}
                nextUrl="/get-started/4" 
                headingText = "Status" 
                text="What is your relationship status?"
                backUrl="/get-started/2" />
          </Route>
          <Route exact path="/get-started/4">
              <Onboard
                questionId='q4'
                animate={false} 
                options={[
                  {label:'Yes',value: true},
                  {label:'No',value: false}]}
                nextUrl="/get-started/5" 
                headingText = "Children?" 
                text="Are you a parent?"
                backUrl="/get-started/3"/>
          </Route>
          <Route exact path="/get-started/5">
              <Onboard
                questionId='q5'
                animate={false} 
                options={[
                  {label:'Physical Health',value:'PHYSICAL'},
                  {label:'Mental Health',value:'MENTAL'},
                  {label:'Family & Friends', value:'FAMILY'},
                  {label:'Love Life', value:'LOVE'},
                  {label:'Career',value: 'CAREER'},
                  {label:'Personal Growth',value:'PERSONAL'},
                  {label:'Finance',value:'FINANCE'},
                  {label:'Fun & Entertainment', value:'FUN'}]}
                nextUrl="/get-started/6" 
                headingText = "Three Goals" 
                text="Finally, choose top 3 priorities you want to focus on now"
                backUrl="/get-started/4"
                selectCount={3} />
          </Route>
          <Route exact path="/get-started/6">
            <HHSignup></HHSignup>
          </Route>
          <Route exact path="/get-started/success">
            <Success></Success>
          </Route>
          <Route exact path="/checkin/done">
            <CheckInDone/>
          </Route>
          <Route path="/checkin">
          <Checkin
                animate={false} 
                options={[
                  {label:'Yes',value: true},
                  {label:'No',value: false}]}
                nextUrl="/checkin/done" 
                headingText = "Just checking in" 
                text="Have you completed your task today?"
               />
          </Route>
          <Route exact path="/terms">
            <HHTermsOfService></HHTermsOfService>
          </Route>
          <Route exact path="/privacy">
            <HHPrivacyPolicy></HHPrivacyPolicy>
          </Route>
          <Route path="/report">
            <Report></Report>
          </Route>
          <Route path="/">
              <HappHabitLandingPage/>
          </Route>

        </Switch>
      </Router>
     </>
   );
 }

