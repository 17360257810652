import React from 'react'
import { Checkbox } from 'antd';

class ControlledCheckboxGroup extends React.Component {

  state = {
    options: this.props.options.map ((item) => {
      return { label:item.label, value:item.value, checked:false, disabled: false}
    }),
  };



  onChange = (checkedValues ) => {
    if(checkedValues.length === this.props.maxCheckCount) {
        let newOptions = [...this.state.options]
        let optionsToDisable = newOptions.filter(element => !checkedValues.includes(element.value))


        let  modNewOptions = newOptions.map(i => {
          if(optionsToDisable.map(k => k.value).includes(i.value)) {

            return {...i, disabled : true}
          }
          return i;
        });
       

        this.setState({options:modNewOptions})
    } else {
      let newOptions = [...this.state.options]
      let  modNewOptions = newOptions.map(i => {
        

          return {...i, disabled : false}
      });
      this.setState({options:modNewOptions})
    }
    this.props.onChange(checkedValues);

  };

  render() {
    return (
      <>
       
        <Checkbox.Group onChange={this.onChange} >
          {this.state.options.map(element => {
            return <Checkbox key={element.value} value={element.value} checked={element.checked} disabled={element.disabled}>{element.label}</Checkbox>
          })}
        </Checkbox.Group>

      </>
    );
  }
}

export default ControlledCheckboxGroup