import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as MessageIcon } from "feather-icons/dist/icons/message-square.svg";
import { ReactComponent as CheckIcon } from "feather-icons/dist/icons/check.svg";
import { ReactComponent as NightIcon } from "feather-icons/dist/icons/moon.svg";




import TeamIllustrationSrc from "images/SMS.gif";
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-10 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-4/12 flex-shrink-0 h-auto pt-10 justify-between`;
// const VideoColumn = tw.div`md:w-5/12 flex-shrink-0 h-80 md:h-auto flex-shrink-0 self-center`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded bg-contain bg-no-repeat bg-center h-full`
// ]);

const Image = tw.img`rounded-2xl border-15	`;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left text-gray-700`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-300`;
const FeatureDescription = tw.div`mt-1`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Achieve your goals with <span tw="text-primary-300">SuperStart</span>
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonText = "See Our Portfolio",
  primaryButtonUrl = "https://timerse.com",
  features = null,
  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: UserIcon,
      title: "Choose top Priorities",
      description: "Physical Health, Mental Health, Friends & Families, Love life, Personal Growth, Finance, Fun & Recreation"
    },
    {
      Icon: MessageIcon,
      title: "Work on the customized daily to-do",
      description: "Move towards your goal by working on daily to-do based on your priorities, One step a day"
    },
    {
      Icon: NightIcon,
      title: "Check in at night",
      description: "Unwind after a long day, and check in the status of your to-do. Every small achievement is a success"
    },
    {
      Icon: CheckIcon,
      title: "Improve with progress report",
      description: "Visualize your progress with SuperStart weekly report, with bonus tips to help you become even better"
    }
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
       
      <ImageColumn>
      <Image src={TeamIllustrationSrc}></Image>
          {/* <Image imageSrc={TeamIllustrationSrc} /> */}
        </ImageColumn>
        
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {/* <Subheading>{subheading}</Subheading> */}
            <Heading>{heading}</Heading>
            {/* <Description>{description}</Description> */}
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>{feature.description}</FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
            {/* <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
